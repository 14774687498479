export default {
  default: {
    selected: "Selected",
    jumpNavigation: 'Jump to navigation',
    jumpContent: 'Jump to main content',
    prevPage: 'Previous page',
    'Enter Your Basic Information': 'Enter Your Basic Information',
    yes: 'Yes',
    no: 'No',
    saved: 'Successfully saved!',
    updated: 'Successfully updated!',
    needAll: 'Please answer for all questions!',
    notAllowed: 'Found not allowed words'
  },
  home: {
    welcome: 'Welcome to',
    almost: 'Almost done!',
    almost2: 'Register with a social account below to get started.',
    how: 'How does RoomSync use my social account?',
    howInfo1: 'The RoomSync app uses your social account to log you into RoomSync. We will not send any e-mail notifications to the account you decide to use and don’t automatically post anything to your social account when you join RoomSync.',
    howInfo2: 'You can only register with one social account and cannot switch the social account associated to your RoomSync account in the future. If you have questions, you can always contact us at support@roomsync.com.',
    howInfo2NoEmail: 'You can only register with one social account and cannot switch the social account associated to your RoomSync account in the future. If you have questions, you can always contact us at',
    already: 'Already registered this year?',
    signIn: 'Sign In',
    register: 'Register',
    noAccount: 'Do not have an account?',
    needHelp: 'Need help?',
    contactUs: 'Contact us!',
    agree: 'By signing up, you agree to our',
    terms: 'Terms of Service',
    privacy: 'Privacy Policy',
    withFacebook: '{{label}} with Facebook',
    withGoogle: '{{label}} with Google',
    withApple: '{{label}} with Apple',
    getMobile: 'Please get our mobile app, it’s great!',
    onlyMobile: 'RoomSync website can’t provide you the level of user experience we would like to on mobile devices.',
    facebookUnavailable: 'Facebook Login unavailable. Please check your privacy settings!',
    googleUnavailable: 'Google Login unavailable',
    forgotLogin: 'Forgot your login?',
    enterAddress: 'Enter your email address',
    emailPlaceholder: 'Email address',
    submit: 'Submit',
    methodInfo: 'Our records show that you signed in with a {{types}} account. Please try signing in with all {{types}} accounts that you have access to even if they are different than the email address where you receive RoomSync notifications. If you are still unable to login, contact us at support@roomsync.com for assistance.',
    methodInfoNone: 'There is no RoomSync account associated with this e-mail.',
  },
  about: {
    title: 'About Me',
    save: 'Save',
    noPreferences: 'No Preferences',
    roommateRespond: 'How should your roommates respond?',
    roommateRespond2: 'How should your roommate respond for question above?',
    saveAria: 'Save button'
  },
  chat: {
    title: 'Chats',
    noConversations: 'You have no conversations.',
    unavailable: 'Chat is not available.',
    remove: 'Remove',
    cancel: 'Cancel',
    chat: 'Chat',
    last: 'Last message at',
    you: 'You',
    removePrompt: 'Are you sure you want to remove the message?',
    inputPlaceholder: 'Type a message...',
    firstMessage: 'First message from {{from}}. Created at {{createdAt}}',
    createdAt: 'Created at {{createdAt}}',
    messageFrom: 'Message from {{from}}',
    report: 'Report',
    join: 'Join',
    decline: 'Decline',
    wantJoin: 'Would you like to join?'
  },
  community: {
    title: 'Community',
    infoContent: `
    The matching period in RoomSync will end at 8:00pm EST on the ending date shown here.\n\nThe matches that appeared in your Current Roommate Matches section of the Matches tab as of the matching period ending are the matches your housing office will receive as your roommate requests.\n\nIf you do not choose all of your roommates before the matching period ends, we recommend reaching out to your housing office to ask about their roommate assignment policies.`,
    infoLabel: 'Matching End Date',
    matchingEnd: 'RoomSync Matching Ends:',
    newPost: 'New Post',
    newMessage: 'New post message',
    postLimitError: 'Community post has to be 1 to 1500 characters!',
    post: 'Post',
    info: 'Community Info',
    posts: 'Community Posts',
    common: 'In common',
  },
  housing: {
    title: 'Housing',
    searchPlaceholder: 'Type a housing option',
    setHousing: 'Set Housing',
    add: 'Add Housing Options'
  },
  main: {
    community: 'Community',
    chat: 'Chat',
    find: 'Find',
    matches: 'Matches',
    welcome: 'Welcome'
  },
  majors: {
    title: 'A list of available majors',
    searchPlaceholder: 'Type a university major',
    setMajors: 'Set Majors',
    add: 'Add Academic Majors'
  },
  matches: {
    title: 'My Roommate Matches',
    infoRoommate: 'Congratulations on fully completing your matching request! Now that your unit is full, incoming and outgoing match requests have been disabled. If you or one of your requested roommates leaves your Current Roommate Matches, you will once again be able to send and receive new requests.',
    directions: 'Community Directions:',
    leaveTitle: 'Are you sure you want to exit your current roommate matches?',
    leaveCurrent: 'Yes, exit my current roommate matches',
    no: 'Nevermind',
    find: 'Find a Roommate Match',
    dontSee: 'Why don\'t I see a request?',
    current: 'Current Roommate Matches',
    currentInfo: 'Current Roommate Matches are requests that you sent or received that have been accepted. This list may also include roommates that were already matched with someone you selected, as well as matches that your community staff has made on your behalf.',
    compatible: 'compatible',
    likes: '{{firstName}} Likes You, Too!',
    view: 'View {{firstName}}\'s Profile',
    showProfiles: 'View Your Liked Profiles',
    noMatches: 'You have no current roommate matches.',
    leave: 'Exit My Current Roommate Matches',
    unshare: 'Are you sure you want to unshare bedroom with {{name}}?',
    share: 'Are you sure you want to share bedroom with {{name}}?',
    congratulations: 'Congratulations!',
    incoming: 'Incoming Requests',
    outgoing: 'Outgoing Requests',
    outgoingRequests: 'Outgoing Requests ({{count}})',
    incomingRequests: 'Incoming Requests ({{count}})',
    looking: 'Looking for more roommates?',
    keep: 'Keep Searching',
    or: ' or ',
    viewSaved: 'View your Saved Swipes.',
    noRequests: 'You have no requests.',
    unshareLabel: 'Unshare',
    openMatchesLabel: 'Open current roommate matches description modal',
    cancel: 'Cancel',
    save: 'Save',
    shareLabel: 'I want to share my bedroom with them',
    knowLabel: 'I knew them before Roomsync and they are my preferred roommate.',
    knowLabelStrong: 'I knew <b>{{name}}</b> before Roomsync and they are my preferred roommate.',
    faq: {
      accordion0: {
        title: "I had roommates listed here - where did they go?",
        content:
          "When RoomSync closed for matching, the Current Roommate Matches that were shown here are now being reviewed for final approval by your housing office. \n\nNote, your housing office may make changes to your roommate group based on their room availability."
      },
      accordion1: {
        title: "I did not find any roommates on RoomSync or I didn’t find all the roommates needed to fill my apartment - what happens now?",
        content: "Your housing office will use your lifestyle preferences to choose a roommate for you when they complete room assignments. \n\nIf you have a specific roommate in mind who you did not match with on RoomSync, please reach out to their office directly with your request. \n\nNote, RoomSync never creates matches and is not involved in your housing office’s matching or room assignment process.", 
        
      },
      accordion2: {
        title: "Before RoomSync closed for matching, I saw a match here that I did not choose - why?",
        content: 
          "RoomSync never chooses roommates for you. If you saw a roommate you did not choose, this could mean that:\n\n1. An existing roommate of yours matched with this roommate which added them to your Current Roommate Matches. \n\n2. Your match was created in your housing office’s system and imported into RoomSync. \n\n3. Someone at your housing office used RoomSync administrative tools to create the match.", 
        
      },
      accordion3: {
        title: "How do I find out my final match and room assignment?",
        content: 
          "Your housing office will communicate your final matches and room assignments to you directly, outside of RoomSync. \n\nIf you have any questions about your final match or room assignment, please reach out to your housing office directly. \n\nNote, RoomSync is not authorized to make any changes to your match requests.", 
      },
    }
  },
  more: {
    title: 'More',
    mobileApp: 'Mobile App',
    editProfile: 'Edit Profile',
    update: 'Update Basic Info',
    switch: 'Switch Membership',
    contact: 'Contact Us',
    logout: 'Logout',
    signedWith: 'You are signed in with {{type}}',
    logoutTitle: 'Are you sure you want to logout?',
    yes: 'Yes',
    no: 'Nevermind',
    language: 'Language',
    delete: 'Delete Account',
    notification: '{{count}} notification',
    notifications: '{{count}} notifications',
  },
  network: {
    searchPlaceholder: 'Type your university, proper...',
    closed: 'This Network is Closed',
    info1: 'We know you\'re excited to find a\nroommate - we\'re excited for you too!',
    info2: 'Please check back during the roommate\nmatching period from',
    info3: '\nthrough',
    info4: ' to start\nsearching for your roommate.\n\nPlease contact us if you have any further questions!',
    contact: 'Contact us if you need help at'
  },
  noNetwork: {
    title: 'Register with Invite Link',
    gotLink: 'Got an Invite Link?',
    topDescription: 'You can only access a community matching group in RoomSync through a special invitation link. Depending on your community, this could be sent by them or directly by us at RoomSync',
    bottomDescription: 'If you\'ve previously accessed your matching group, you may now be logged in with a different Facebook, Google, or Apple account that we don\'t recognize. If you\'re not sure what account you used, contact us at ',
    forHelp: '  for help.',
    logOut: 'Log Out',
    goBack: 'Go Back'
  },
  passcode: {
    label: 'Passcode',
    continue: 'Continue',
    invalid: 'Invalid passcode'
  },
  preview: {
    about: 'About Me',
    social: 'Social Accounts',
    majors: 'Majors',
    housing: 'Housing',
    roommates: 'Roommates',
    questions: 'Questions',
    gender: 'Gender:',
    residentStatus: 'Resident Status'
  },
  profile: {
    title: 'Profile',
    matched: 'Matched',
    compatible: '% compatible',
    deny: 'Deny',
    accept: 'Accept',
    cancelRequest: 'Cancel Request',
    sendRequest: 'Request Roommate',
    sendRequestTooltip: 'Your housing office has closed roommate self-selection for this year. For this reason, you will no longer be able to request roommates.',
    cancel: 'Cancel',
    hidden: 'Your Profile is Hidden!',
    hiddenInfo: 'Hidden profiles can\'t view this content :(',
    unhideInfo: 'You can unhide your profile anytime',
    byUpdating: 'by updating your status',
    here: 'here!',
    startChat: 'Start chat',
    request: 'Request roommate',
    viewProfile: 'View profile',
    network: 'Network',
    subnetwork: 'Matching Round',
    unit: 'Unit type',
    room: 'Room type',
    selectMembership: 'Select a Membership',
    pressEnter: 'To view a current profile press Enter',
    preview: 'Preview profile',
    fullname: 'Your full name {{name}}',
    chatWith: 'Chat with roommate',
    callWith: 'Call to roommate',
    settings: 'Profile Settings',
    details: 'Profile Details',
    removedTitle: 'RoomSync access has been removed',
    removedInfo: 'Your property administrator has removed you as a user and you are no longer authorized to access this matching group. If you have questions, please contact',
    deletedTitle: 'Your RoomSync account has been deleted.',
    noBio: 'No bio available',
  },
  questions: {
    save: 'Save',
    title: 'About Me',
    alertTitle: 'About Me Response Required',
    alertDescription: 'Please answer your About Me questions before continuing to find your roommate on RoomSync.',
  },
  request: {
    title: 'Request',
    emptyError: 'Message can\'t be empty!',
    defaultError: 'Rooming with this person will result in too many people in your room. Sorry!',
    sendRequest: 'Send Roommate Request',
    sent: 'Request Sent',
    view: 'View Roommate Request',
    messagePlaceholder: 'Message...',
    inBedroom: 'Would share bedroom with you.',
    inCommon: 'Would share common spaces with you.',
  },
  swipes: {
    title: 'My Swipes',
    skipped: 'Skipped',
    liked: 'Liked',
    mutual: 'Mutual',
    oneWay: 'One Way',
    noCards: 'You have no cards.',
    noMore: 'No more cards',
    loadMore: 'Load more',
    checkLater: 'Check back later!',
    noSearchResults: 'No Search Results',
    skippedAll: 'You swiped all potential roommates so far.\nAs more join, they will appear here.',
    updateSearch: 'Update your search filters to see more users.',
    whyDont: 'Why don\'t I see more users?',
    reviewSwipes: 'Review your swipes',
    ohNo: 'Oh no!\nThe person you\'re looking for is not available.',
    wontBeAble: 'You won\'t be able to find this person if...',
    thisPerson: '• This person ',
    notRegistered: 'hasn\'t registered an account',
    yet: ' with RoomSync yet.',
    thisPersonHas: '• This person has an',
    apartment: ' apartment type or bedroom type ',
    notCompatible: 'that is not compatible with yours based on the configuration of your unit.',
    both: '• You are both ',
    renewing: ' renewing residents. ',
    noHousing: 'Your housing office can match you together if you are both renewing residents and your match isn\'t showing on RoomSync.',
    thisPersonAlready: '• This person is already',
    fully: ' part of a fully completed match group ',
    noSpaces: 'with no spaces available for more roommates.',
    findMore: 'Find More Roommates',
    checked: 'If you\'ve checked on the reasons above and think you should be able to find this person, ',
    letKnow: 'let us know.',
    like: 'Like',
    skip: 'Skip',
    view: 'View',
    request: 'Request',
    next: 'Next',
    back: 'Back',
    userSwiped: 'You already swiped this user.',
    userSwipedInfo: 'We found results for users with that name in your My Swipes area',
    fullTitle: 'Congrats - your match group is full!',
    fullInfo: 'Since you have no spaces left in your group, you won\'t see any new cards to swipe.',
    fullButton: 'View your match group',
  },
  search: {
    title: 'Search',
    byPreferences: 'Search By Preferences',
    byName: 'Search By Name',
    firstName: 'First Name',
    lastName: 'Last Name',
    majors: 'Academic Majors',
    housing: 'Housing Options',
    group: 'Group Size',
    groupSize: 'Group Size: {{groupSize}}',
    apply: 'Apply Room Filter',
    byApplying: 'By applying room filter, only users who still need roommate(s) will be displayed.',
    clear: 'Clear All Filters',
    submit: 'Apply Filters',
    any: 'Any',
    lifestyle: 'Lifestyle',
    room: 'Room',
    results: 'Search Results for: {{name}}',
    clearSearch: 'Clear Search',
    clearFilters: 'Clear Filters',
    editMajors: 'Edit academic major',
    editHousing: 'Edit housing options',
    filters: 'Filters',
    gender: 'Gender',
    registration: 'Registration Status'
  },
  social: {
    title: 'Social Accounts',
    heading: 'Add your social accounts',
    add: 'Add',
    channel: 'channel',
    no: 'You have no',
    invalid: 'Invalid {{label}}',
    username: 'username',
    invalidUsername: 'Invalid username',
    noAccount: 'You have no account'
  },
  thread: {
    title: 'Post',
    new: 'New Thread',
    by: 'by',
    replies: 'replies',
    reply: 'reply',
    noPosts: 'No posts yet',
    newPost: 'New post',
    newPostPlaceholder: 'Type a post...',
    sendAria: 'Send post',
    postAria: 'Post replies. To navigate through replies use up/down arrow keys'
  },
  verify: {
    updateTitle: 'Update Basic Info',
    welcome: 'Welcome to ',
    review: 'Review Your Basic Information',
    tooltip1: 'Your community does not allow you to modify your basic information. If you see something inaccurate, please contact us at support@roomsync.com.',
    tooltip2: 'If this field is left blank, the first name sent to us by your community will be displayed to other users.',
    tooltip3: 'If this field is left blank, the last name sent to us by your community will be displayed to other users.',
    tooltip4: 'If this field is left blank, RoomSync notifications will be sent to the e-mail on file with your community. If a valid e-mail address is entered in this field, RoomSync notifications will be sent only to this preferred e-mail address. Please note that e-mail addresses are not visible to other users in RoomSync.',
    tooltip5: 'By hiding your profile, you will no longer appear in results for other users. If someone has already Liked or Skipped you, your card will still appear in their Saved Swipes.\n\nHiding your profile will not affect your current roommate matches. If you are matched with someone, you will stay matched. You will continue to receive notifications for any new activity related to your account such as new roommates, new chat messages or someone leaving your match.',
    tooltip6: 'Your phone number will be shared with {{networkName}}',
    no: 'NO',
    yes: 'YES',
    firstName: 'First Name',
    firstNameLimit: 'First name has to be 1 to 50 characters',
    lastName: 'Last Name',
    lastNameLimit: 'Last name has to be 1 to 50 characters',
    preferredTitle: 'Preferred Name',
    preferredInfo: 'Would you like your name to appear differently than above to other users?',
    preferredFN: 'Preferred First Name (optional)',
    preferredLN: 'Preferred Last Name (optional)',
    preferredFirstNameLimit: 'Preferred first name has to be 1 to 50 characters',
    preferredLastNameLimit: 'Preferred last name has to be 1 to 50 characters',
    email: 'E-mail',
    preferredE: 'Preferred E-mail (optional)',
    emailError: 'Please provide correct email address',
    phoneError: 'Please provide correct phone number',
    bio: 'Bio',
    gender: 'Gender',
    hide: 'Hide Your profile',
    phoneNumber: 'Phone',
    hideDescription: 'Would you like to hide your profile?\nDoing this will hide it from appearing in\nFind tab results for other users.',
    update: 'Update',
    continue: 'Continue',
    genderRequired: 'Basic Information Required',
    genderDescription: 'Please answer all questions in your basic information section on RoomSync',
    pronoun: 'Personal Pronouns',
    pronounOptional: 'Personal Pronouns (optional)',
    pronounTooltip: ' Share your personal pronouns with others. You can update your response at any time.',
    pronounError: 'Please contact support@roomsync.com with submitting your personal pronouns.',
    quaranteeLabel: 'I understand that roommate requests and matches are subject to change and cannot be guaranteed by the Community Leasing Team.',
    proceed: 'Proceed',
    supportButton: 'Contact Support',
  },
  block: {
    title: 'Chat settings',
    block: 'Block {{type}}',
    unblock: 'Unblock {{type}}',
  },
  confirmation: {
    title: 'Leave without saving changes?',
    yes: 'Yes',
    no: 'Nevermind',
  },
  feedback: {
    title: 'Feedback'
  },
  gender: {
    cancel: 'Cancel',
    notSelected: 'Not Selected',
    pleaseSelect: 'Please select your gender',
    info: 'RoomSync asks for your gender solely for the purposes of roommate matching. Your gender will not be displayed to other users.\n\nPlease choose the option that you feel is the best fit for you.\n\nIf you need to make a change to your gender selection in the future, contact RoomSync.',
    lockedSubtitle: 'Why is this locked?',
    lockedInfo: 'RoomSync Support does not have the ability to change this selection. You must contact your community’s leasing office to inquire about availability to make a change to this selection. Once they change this in their system, your selection will update in RoomSync within 24 hours',
  },
  invitation: {
    title: 'Are you {{firstName}} {{lastName}}?',
    info: 'If you continue {{firstName}} {{lastName}}\'s one-time use invitation link will be claimed. If you are not this person and you use this invitation, your roommate matches will not be accepted.',
    confirm: 'Yes, I am {{firstName}} {{lastName}}',
    notMe1: 'No, that\'s my guarantor.',
    notMe2: 'No, I\'m unrelated to this person.',
    notMe3: 'Not quite, I\'m the guarantor.',
  },
  demo: {
    skipTitle0: 'Not Interested?',
    skipDesc0: 'If you\'re not interested in someone, you can "Skip" them for now.',
    skipFooter0: 'SKIP THIS CARD NOW',
    likeTitle0: 'Changed Your Mind?',
    likeDesc0: 'These are users you’ve skipped. You can change your decision and like them by swiping their card to the right.',
    likeFooter0: 'LIKE THIS CARD NOW',
    skipTitle1: 'Not Interested?',
    skipDesc1: 'If you\'re not interested in someone, you can "Skip" them for now. You can always change your mind.',
    skipFooter1: 'SKIP THIS CARD NOW',
    likeTitle1: 'Finding Roommates',
    likeDesc1: 'Anonymously "Like" users to the right. If they like you too, we’ll let you know so you can chat about being roommates.',
    likeFooter1: 'LIKE THIS CARD NOW',
    toSkip: 'To skip card press left arrow keyboard key',
    toLike: 'To like card press right arrow keyboard key'
  },
  languages: {
    title: 'Language',
    en: 'English',
    zh: '中文（简体)',
    pl: 'Polski',
    es: 'Español',
    ua: 'Українська',
    ru: 'Pусский',
    soon: 'Language available soon!',
  },
  notFound: {
    title: 'Page Not Found',
    oh: 'Oh No!',
    description: 'The page you’re looking for cannot be found. Please contact support@roomsync.com or call 352.327.4061 ext. 2 for assistance.'
  },
  mobile: {
    title: 'RoomSync Mobile App',
    desc1: 'You can use RoomSync on your iOS or Android device to find a roommate. Just search for the RoomSync app in the App Store or in Google Play.',
    desc2: 'Be sure to sign in with your same social account on the mobile app to ensure you can access all your existing account information.'
  },
  video: {
    join: 'Join Now',
    joning: 'Joining Meeting',
    calling: 'Calling',
    joinTitle: 'Join to video call with',
    callTitle: 'Start video call with',
    call: 'Call',
    cancel: 'Cancel',
    reconnecting: 'Reconnecting...',
    you: '(You)',
    disconnect: 'Disconnect'
  },
  welcome: {
    title: 'Welcome',
    closed: 'RoomSync is Currently Closed',
    paused: 'Matching for Your Unit Type is Closed',
    desc1: 'The deadline for choosing a roommate on RoomSync has passed',
    desc1_1: ' however you have access to other ',
    desc1_2: 'memberships',
    desc1_3: ' that may still be open for matching',
    desc1_4: '. Once matching closes, you are no longer able to see potential roommates, accept requests, or modify matches.',
    desc2: 'Didn\'t find a roommate on RoomSync?',
    desc3: 'We recommend contacting your housing office to ask how to proceed with roommate selection or to give them your preferences.',
    desc4: 'Made a match?',
    desc6: 'RoomSync closed early?',
    desc7: 'Sometimes housing offices need to close matching sooner than expected. If you have questions about the RoomSync deadline, please ask your community as these dates are managed by their office.',
    welcome: 'Welcome to RoomSync!',
    welcome1: 'Please complete your roommate matching profile and share your preferences ',
    welcome1_button: 'here.',
    welcome1_2: '\nYour community will do their best to match you with compatible roommates based on your responses.',
    welcome1_3: ' You can also access your other memberships ',
    welcome1_4: 'here.',
    welcome2: 'For tips on completing your profile, check out the ',
    welcome2_button: 'Community tab.',
    welcome3: 'If you have questions about matching procedures or room assignments, please reach out to your community directly. If you need help using RoomSync, click ',
    welcome3_button: 'Contact Us',
    welcome3_2: ' to get in touch.',
    welcomePaused: "While some residents at your community may still be using RoomSync, your community has closed matching for your unit type at this time.  Once matching closes, you are no longer able to see potential roommates, accept requests, or modify matches.  For questions about why matching is closed for your unit type, please reach out to your community's leasing office.",
  },
  delete: {
    title: 'Delete Account',
    info: 'Would you like to delete your RoomSync account? This action cannot be undone and will permanently delete all information you provided to RoomSync. Instead of deleting your account, you can choose to ',
    hideProfile: 'hide your profile here.',
    confirmTitle: 'Are you sure that you want to delete your account?',
    confirm: 'Yes, delete my account',
    nevermind: 'Nevermind'
  },
  ai: {
    enterKeyword: 'Enter keyword',
    keyword: 'Keyword',
    title: 'AI Profile Assistant',
    writeForMe: 'Write this for me',
    listUp: 'List up to three keywords for your answer',
    cancel: 'Cancel',
    next: 'Next',
    pickUp: 'What are you like? (Pick up to {{count}}/3)',
    back: 'Back',
    generate: 'Generate',
    calm: 'Calm',
    ambitious: 'Ambitious',
    friendly: 'Friendly',
    introverted: 'Introverted',
    organized: 'Organized',
    extroverted: 'Extroverted',
    honest: 'Honest',
    creative: 'Creative',
    determined: 'Determined',
    curious: 'Curious',
    dependable: 'Dependable',
    empathetic: 'Empathetic',
    flexible: 'Flexible',
    independent: 'Independent',
    kind: 'Kind',
  }
}
