import { useCallback } from 'react'
import PropTypes from 'prop-types'
import { show } from 'redux-modal'
import { connect } from 'react-redux'
import images from 'static'
import cn from 'classnames'

import c from './MatchAvatar.module.scss'

const MatchAvatar = ({ data: { avatar, match, claimed }, size, white, red, showModal }) => {
  const onNotClaimedClick = useCallback((e) => {
    e.stopPropagation()

    showModal('infoModal', {
      label: 'Profile Not Created',
      description: 'This resident has not yet logged into RoomSync to create their matching profile. You can still request them to be your roommate and they will receive an email notification.'
    })
  }, [claimed])

  return (
    <div className={cn(c.container, size === 'small' && c.small)}>
      <img
        alt=""
        src={images.defaultAvatar}
        rel='noreferrer'
        aria-hidden="true"
        className={cn(c.avatar, size === 'small' && c.small)}
      />
      <section>
      {
        claimed !== false
          ? (
            <div className={cn(c.percentWrapper, size === 'small' && c.small)} aria-label={`${match}% compatible`}>
              <svg
                width="80"
                height="80"
                viewBox="0 0 80 80"
                className={cn(c.circularChart, size === 'small' && c.small)}
              >
                <circle
                  cx="40"
                  cy="40"
                  r="32"
                  fill="none"
                  stroke="#e6e6e6"
                  strokeWidth="10"
                />
                {match > 0 && (
                  <circle
                    cx="40"
                    cy="40"
                    r="32"
                    fill="none"
                    stroke={white ? '#ffffff' : (red ? '#D65252' : '#39992A')}
                    strokeWidth="10"
                    strokeDasharray={`${match * 2.387}, 239.389`}
                    transform="rotate(-90 40 40)"
                    className={c.progressCircle}
                  />
                )}
                <text
                  x="40"
                  y="40"
                  textAnchor="middle"
                  dominantBaseline="middle"
                  fontSize="20"
                  fontWeight="600"
                  fill={white ? '#ffffff' : '#000000'}
                >
                  {`${match}%`}
                </text>
              </svg>
            </div>
          )
          : (
            <div className={cn(c.notClaimedContainer, size === 'small' && c.small)}>
              <div className={c.notClaimedWrapper} onClick={onNotClaimedClick}>
                <span>Profile Not Created</span>
              </div>
            </div>
          )
      }
      </section>
    </div>
  )
}

MatchAvatar.propTypes = {
  data: PropTypes.shape({
    match: PropTypes.number,
    avatar: PropTypes.string
  }).isRequired,
  size: PropTypes.string,
  white: PropTypes.bool
}

MatchAvatar.defaultProps = {
  size: 'large',
  white: false
}

const mapDispatchToProps = {
  showModal: show
}

const ConnectedMatchAvatar = connect(null, mapDispatchToProps)(MatchAvatar)

export { ConnectedMatchAvatar as MatchAvatar }
